import React from 'react';
import { useTranslation } from 'react-i18next';

import { navigate } from 'gatsby';

import Button from 'src/components/common/Button/Button';
import Header from 'src/components/Header/Header';
import Footer from 'src/components/Landing/Footer/Footer';
import { Section } from 'src/constants';

import {
  StyledWrapperPageNotFound,
  StyledTitle,
  StyledTextContent,
  StyledSubtitle,
  StyledBlockPosition,
  StyledContentWrapper,
  StyledErrorImg,
  StyledButton,
  StyledContent500Page,
} from './styled';
import Warning500Img from './warning500.svg';

const Error500 = () => {
  const { t } = useTranslation();

  const handleClickGoHomeButton = () => {
    navigate(`/#${Section.Top}`);
  };

  return (
    <StyledWrapperPageNotFound>
      <Header showOnlyHeaderPanel />
      <StyledBlockPosition>
        <StyledErrorImg img={Warning500Img} error="500" />
        <StyledContentWrapper>
          <StyledTitle variant="h1">{t('pageUnexpectedError.title')}</StyledTitle>
          <StyledSubtitle variant="h2">{t('pageUnexpectedError.subtitle')}</StyledSubtitle>
          <StyledContent500Page variant="h5">
            {t('pageUnexpectedError.contentFirstPart')}
            <br />
            {t('pageUnexpectedError.content')}
          </StyledContent500Page>
          <StyledTextContent variant="h5">{t('pageUnexpectedError.contentSecondPart')}</StyledTextContent>
          <StyledButton onClick={handleClickGoHomeButton}>
            <Button>{t('pageNotFound.buttonText')}</Button>
          </StyledButton>
        </StyledContentWrapper>
      </StyledBlockPosition>
      <Footer />
    </StyledWrapperPageNotFound>
  );
};

export default Error500;
